import React, { useEffect } from 'react'

const Impressum = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <>
            <div className="outpage_banner" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className='fw-bold text-center text-white'>Impressum</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="datenschutzs_content py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2><strong>Impressum</strong></h2>
                            <br />
                            


<p><b>Verlag</b> </p><br></br>
<p>Books on Demand GmbH</p>
<p>In de Tarpen 42</p>
<p>22848 Norderstedt</p>
<p>Deutschland</p><br></br>
<p>Geschäftsführung: Dr. Marko Kuck, Yogesh Torani, Alyna Wnukowsky</p>
<p></p>
<p>Mitglied im Börsenverein des Deutschen Buchhandels e.V.</p>
<p>Verkehrsnummer: 11 507</p>
<p>Teilnehmer am BAG-Abrechnungsverfahren</p>
<p>Umsatzsteuer-ID-Nummer: DE 212971392</p>
<p>Handelsregister: Amtsgericht Kiel HRB 4551 NO</p>
<p>Die Europäische Kommission stellt unter <a href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a> eine Plattform zur Online-Streitbeilegung (OS) bereit. Die Books on Demand GmbH ist jedoch nicht verpflichtet und nicht bereit, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
<p></p><br></br>
<p><b>Verantwortlich für die Webseite:</b></p>
<p>Andre Reher</p>
<p>An der Steinhalde 22</p>
<p>44532 Lünen</p>
<p>arden.rheer@yahoo.com</p><br></br>
<p></p>
<p><b>Konzeption, Design, Umsetzung der Website</b></p>
<p>Robin Reher</p>
<p>hi@robdev.de</p>
<p>Haftungsausschluss</p>
<p>Alle Hinweise auf externe Seiten, sogenannte Links, begründen keine inhaltliche Verantwortung des Verlages, <br></br>sondern sind allein von dem jeweiligen Dienstanbieter zu verantworten.</p>
<p></p>
<p></p>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Impressum