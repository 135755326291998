import React from 'react'
import amazon from '../../assets/img/amazon.png'
import bookMockup from '../../assets/img/book_mockup.png'
import './ErlebeDas.scss'
const ErlebeDas = () => {
    return (
        <div className="erlebe-das-area">
            <div className="container">
                <div className="row row-gap">
                    <div className="col-lg-5">
                        <div className="erlebe-text">
                            <div className="section-title border-left">
                                <div className="h2 text-white">ERLEBE DAS ABENTEUER</div>
                            </div>
                            <p className='text-white mt-4'>Gehe zusammen mit Sam Docker <br />  in den Kampf gegen die Diktatur! </p>
                            <a href="https://www.amazon.de/2090-Deine-Zukunft-Die-Antarfari-Crew/dp/3756885585/ref=sr_1_1?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3H7XARVV0DSFC&keywords=2090+zukunft&qid=1671202975&sprefix=2090+zukunft%2Caps%2C79&sr=8-1" className="btn-lg">JETZT BESTELLEN!</a>
                            <br />
                            <a href="https://www.amazon.de/2090-Deine-Zukunft-Die-Antarfari-Crew/dp/3756885585/ref=sr_1_1?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3H7XARVV0DSFC&keywords=2090+zukunft&qid=1671202975&sprefix=2090+zukunft%2Caps%2C79&sr=8-1" className='mt-5 d-grid'><img src={amazon} alt="" width={132} height={40} /></a>
                        </div>
                    </div>
                    <div className="col-lg-5 ms-auto">
                        <div className="erlebe-img">
                            <img src={bookMockup} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErlebeDas