import React, { useState, useEffect } from "react";  
import {Link} from 'react-scroll';

// import logo from '../../../assets/img/logo.png'
import { GrClose } from "react-icons/gr"; 
import { GoThreeBars } from "react-icons/go"; 
import './Header.scss'
import Logo from "./Logo";
const Header = () => {
    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click) 
    const closeMenu = () => setClick(false)
    
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []); 
    return (
        <nav className={scroll ? "navbar navBarTop navScroll" : "navbar navBarTop"}>
            <div className="container"> 
                <div className="nav-content">
                    <button class="navbar-toggler d-flex border-0 d-lg-none" type="button" onClick={handleClick}>
                        {/* <Image src={bar} alt='logo' height={20} width={35} />  */}
                        <GoThreeBars/>
                    </button> 
                    <div className="logo-nav">
                        <Logo/>
                        {/* <Link className="navbar-brand nav-link" to="/"> 
                            <img src={logo} alt='logo' height={45} />
                        </Link> */}
                    </div>
                    <div className={click ? "nav-offcanvas active" : "nav-offcanvas"}> 
                        <div className="d-lg-none navClose">
                            <Link onClick={closeMenu}>
                                <GrClose/>
                            </Link>
                        </div>
                        <ul className="navbar-nav"> 
                            <li className="nav-item">
                                <Link to="anterfaricrew" className="nav-link" spy={true} offset={-80} onClick={closeMenu}>
                                    ANTARFARICREW
                                </Link>
                            </li> 
                            <li className="nav-item"> 
                                <Link to="leseprobe" className="nav-link" spy={true} offset={-80} onClick={closeMenu}>
                                    LESEPROBE
                                </Link>
                            </li>  
                            <li className="nav-item"> 
                                <Link to="autor" className="nav-link" spy={true} offset={-80} onClick={closeMenu}>
                                    AUTOR
                                </Link>
                            </li>    
                            <div className="bar-btn-con">
                                <Link href='https://www.amazon.de/2090-Deine-Zukunft-Die-Antarfari-Crew/dp/3756885585/ref=sr_1_1?crid=2JHQ550RZHE8Y&keywords=2090+zukunft&qid=1671042046&sprefix=%2Caps%2C462&sr=8-1' className="nav-btn" onClick={closeMenu}>
                                    JETZT BESTELLEN!
                                </Link> 
                            </div>
                        </ul> 
                    </div> 
                </div>
            </div>
        </nav>
    )
}

export default Header