import React from 'react'
import amazon from '../../assets/img/amazon.png'
import PageFlip from './PageFlip'
import './UberDas.scss'
const UberDas = () => {
    return (
        <div className="uber-das-area" id='leseprobe'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="section-title border-left">
                            
                        </div>
                        <div className="uber-das-text">
                            </div>
                    </div>
                </div>
                
            </div>
            <div className="container-fluid container-lg">
                <div className="row book-row">
                    <div className="col-lg-12">
                        <div className="section-title text-center">
                            <div className="h2 text-white">LESEPROBE</div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="book-slider">
                            <PageFlip/>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="book-btn text-center mt-4">
                            <a href='https://www.file-upload.net/download-15058829/LeseprobeWebsite.pdf.html
' download className="btn-lg">Download Leseprobe</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UberDas