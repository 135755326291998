import React from "react";
import { Link } from "react-router-dom";
import logo from '../../../assets/img/logo.png'
const Logo = () => {
  return (
    <Link className="navbar-brand nav-link" to="/">
      <img src={logo} alt="logo" height={45} />
    </Link>
  );
};

export default Logo;
