import React from 'react'
import dieImg from '../../assets/img/antarfari_crew1.png'
import './DieAntarari.scss'
const dieData =[
    {
        id: 0,
        image: dieImg,
        title: 'GEMEINSAM'
    },
    {
        id: 1,
        image: dieImg,
        title: 'GEGEN'
    },
    {
        id: 2,
        image: dieImg,
        title: 'DIE'
    },
    {
        id: 3,
        image: dieImg,
        title: 'DIKTATUR'
    } 
]
const DieAntarari = () => {
    return (
        <div className="die-antarfari-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center">
                            <div className="h2">DIE ANTARFARI CREW</div>
                            <span></span>
                        </div>
                    </div>
                </div>
                <div className="row row-gap">
                    {dieData.map((item)=>(
                        <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="die-antarfari-item">
                                    <div className="die-img">
                                        <img src={item.image} alt="" />
                                    </div>
                                    <div className="h5">{item.title}</div>
                                </div> 
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default DieAntarari