import React from 'react'
import arden from '../../assets/img/arden.png'
import './Autor.scss'
const Autor = () => {
    return (
        <div className="autor-area" id='autor'>
            <div className="container">
                <div className="row row-gap">
                    <div className="col-lg-6 order-lg-1 align-self-end">
                        <div className="autor-img">
                            <img src={arden} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="autor-text">
                            <div className="section-title border-left">
                                <div className="h2 ">ARDEN RHEER</div>
                            </div>
                            <p>
                            Der Autor wurde im Oktober 1966 in Lünen geboren, wo er auch heute noch zu Hause ist. Sein Name steht für spannende, actionreiche aber auch sehr realistische Zukunftsromane, was seine Werke sehr authentisch wirken lässt. Dies ist der erste Teil einer neuen, spannenden Buchreihe die im Jahr 2090 startet.
                               
                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Autor