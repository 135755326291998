import React from 'react'
import Autor from '../components/Autor/Autor'
import Banner from '../components/Banner/Banner'
import DieAntarari from '../components/DieAntarari/DieAntarari'
import ErlebeDas from '../components/ErlebeDas/ErlebeDas' 
import UberDas from '../components/UberDas/UberDas'


const Home = () => {
  return (
    <> 
        <Banner/>
        <DieAntarari/>
        <UberDas/>
        <Autor/>
        <ErlebeDas/> 
    </>
  )
}

export default Home