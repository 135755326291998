import React, { useRef } from 'react'
import HTMLFlipBook from "react-pageflip";
import bookPage from '../../assets/img/leseprobe1.png'
import bookPage2 from '../../assets/img/leseprobe2.png'
import bookPage3 from '../../assets/img/leseprobe3.png'
import bookPage4 from '../../assets/img/leseprobe4.png'

import { BsArrowLeftSquare,BsArrowRightSquare } from "react-icons/bs";
 
const PageFlip = () => {
    const book = useRef(); 
    return ( 
        <div className='flip-slider'> 
            <HTMLFlipBook
            ref={book}
            width={450} 
            height={630}
            minWidth={315}
            maxWidth={550}
            minHeight={400}
            maxHeight={750}
            maxShadowOpacity={0.5}  
            >
                <div className="demoPage">
                   <img src={bookPage} alt="" />
                </div> 
                <div className="demoPage">
                   <img src={bookPage2} alt="" />
                </div> 
                <div className="demoPage">
                   <img src={bookPage3} alt="" />
                </div> 
                <div className="demoPage">
                   <img src={bookPage4} alt="" />
                </div> 
             
                {/* <div className="demoPage">
                    <p>
                        Lärm außerhalb seines Apartments ließen Sam aufhorchen, was war da denn los?
Er stand von dem Tisch in seiner kleinen Essecke auf und öffnete seine Apartmenttür. Mehrere in schwarze Kampfanzüge gekleidete Männer standen vor dem Nachbarapartment und zwei weitere zerrten gerade seinen Nachbarn mit angelegten Handschellen heraus.
Sam bekam große Augen und erschrak über dieses gewalttätige Vorgehen.
Einer der Uniformierten kam auf ihn zu und befahl ihm: „Bitte gehen Sie zurück in Ihre Wohnung und behindern Sie nicht den Staat bei der Durchsetzung des Rechts der Zentralen Macht.“

                        
                    </p>

                </div> 
                <div className="demoPage">
                    <p>
                        Lärm außerhalb seines Apartments ließen Sam aufhorchen, was war da denn los?
Er stand von dem Tisch in seiner kleinen Essecke auf und öffnete seine Apartmenttür. Mehrere in schwarze Kampfanzüge gekleidete Männer standen vor dem Nachbarapartment und zwei weitere zerrten gerade seinen Nachbarn mit angelegten Handschellen heraus.
Sam bekam große Augen und erschrak über dieses gewalttätige Vorgehen.
Einer der Uniformierten kam auf ihn zu und befahl ihm: „Bitte gehen Sie zurück in Ihre Wohnung und behindern Sie nicht den Staat bei der Durchsetzung des Rechts der Zentralen Macht.“

                    </p>

                </div> 
                <div className="demoPage">
                    <p>
                       Lärm außerhalb seines Apartments ließen Sam aufhorchen, was war da denn los?
Er stand von dem Tisch in seiner kleinen Essecke auf und öffnete seine Apartmenttür. Mehrere in schwarze Kampfanzüge gekleidete Männer standen vor dem Nachbarapartment und zwei weitere zerrten gerade seinen Nachbarn mit angelegten Handschellen heraus.
Sam bekam große Augen und erschrak über dieses gewalttätige Vorgehen.
Einer der Uniformierten kam auf ihn zu und befahl ihm: „Bitte gehen Sie zurück in Ihre Wohnung und behindern Sie nicht den Staat bei der Durchsetzung des Rechts der Zentralen Macht.“

                    </p>

                </div> 
                <div className="demoPage">
                    <p>
                        Lärm außerhalb seines Apartments ließen Sam aufhorchen, was war da denn los?
Er stand von dem Tisch in seiner kleinen Essecke auf und öffnete seine Apartmenttür. Mehrere in schwarze Kampfanzüge gekleidete Männer standen vor dem Nachbarapartment und zwei weitere zerrten gerade seinen Nachbarn mit angelegten Handschellen heraus.
Sam bekam große Augen und erschrak über dieses gewalttätige Vorgehen.
Einer der Uniformierten kam auf ihn zu und befahl ihm: „Bitte gehen Sie zurück in Ihre Wohnung und behindern Sie nicht den Staat bei der Durchsetzung des Rechts der Zentralen Macht.“

                        
                    </p>

                </div> 
                <div className="demoPage">
                    <p>
                       Lärm außerhalb seines Apartments ließen Sam aufhorchen, was war da denn los?
Er stand von dem Tisch in seiner kleinen Essecke auf und öffnete seine Apartmenttür. Mehrere in schwarze Kampfanzüge gekleidete Männer standen vor dem Nachbarapartment und zwei weitere zerrten gerade seinen Nachbarn mit angelegten Handschellen heraus.
Sam bekam große Augen und erschrak über dieses gewalttätige Vorgehen.
Einer der Uniformierten kam auf ihn zu und befahl ihm: „Bitte gehen Sie zurück in Ihre Wohnung und behindern Sie nicht den Staat bei der Durchsetzung des Rechts der Zentralen Macht.“

                    </p> 
                </div> 
                <div className="demoPage">
                    <p>
                       Lärm außerhalb seines Apartments ließen Sam aufhorchen, was war da denn los?
Er stand von dem Tisch in seiner kleinen Essecke auf und öffnete seine Apartmenttür. Mehrere in schwarze Kampfanzüge gekleidete Männer standen vor dem Nachbarapartment und zwei weitere zerrten gerade seinen Nachbarn mit angelegten Handschellen heraus.
Sam bekam große Augen und erschrak über dieses gewalttätige Vorgehen.
Einer der Uniformierten kam auf ihn zu und befahl ihm: „Bitte gehen Sie zurück in Ihre Wohnung und behindern Sie nicht den Staat bei der Durchsetzung des Rechts der Zentralen Macht.“

                        
                    </p> 
                </div> 
                <div className="demoPage">
                    <p>
                       Lärm außerhalb seines Apartments ließen Sam aufhorchen, was war da denn los?
Er stand von dem Tisch in seiner kleinen Essecke auf und öffnete seine Apartmenttür. Mehrere in schwarze Kampfanzüge gekleidete Männer standen vor dem Nachbarapartment und zwei weitere zerrten gerade seinen Nachbarn mit angelegten Handschellen heraus.
Sam bekam große Augen und erschrak über dieses gewalttätige Vorgehen.
Einer der Uniformierten kam auf ihn zu und befahl ihm: „Bitte gehen Sie zurück in Ihre Wohnung und behindern Sie nicht den Staat bei der Durchsetzung des Rechts der Zentralen Macht.“

                </div>  */}
            </HTMLFlipBook>
            <div className="flip-click">
                <button className='flip-button prev' onClick={() => book.current.pageFlip().flipPrev()}>
                    <BsArrowLeftSquare/>
                </button>
                <button className='flip-button next' onClick={() => book.current.pageFlip().flipNext()}>
                    <BsArrowRightSquare/>
                </button>
            </div>
        </div>
    )
}

export default PageFlip