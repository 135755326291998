import { render } from "react-dom";
import CookieConsent from "react-cookie-consent";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';

import './App.scss';
import Home from "./page/Home";
import Impressum from "./page/Impressum";
import Datenschutz from "./page/Datenschutz";
import Footer from "./components/global/Footer/Footer";
import Header from "./components/global/Header/Header";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <div className="wrapper">
      <BrowserRouter>
        <Routes>
          <Route index element={
            <>
              <Header />
              <CookieConsent
  location="bottom"
  buttonText="OK!"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  expires={150}
>
  Diese Webseite benutzt Cookies{" "}
  
</CookieConsent>
              <Home />
              <Footer />
            </>
          } />
          <Route path="/impressum" element={
            <>
              <Header />
              <Impressum />
              <Footer />
            </>
          } />
          <Route path="/datenschutz" element={
            <>
              <Header />
              <Datenschutz />
              <Footer />
            </>
          } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
