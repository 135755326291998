import React from 'react'
import { Link } from "react-router-dom";
import logo from '../../../assets/img/ArdenRheer.png'
import { RiFacebookCircleLine } from "react-icons/ri";
import { AiOutlineAmazon } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import './Footer.scss'

const Footer = () => {
    return (
        <div className="footer-area py-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="footer-content text-center">
                            <div className="footer-logo">
                                <a href="/"><img src={logo} alt="" /></a>
                            </div>
                            <ul className="widget d-lg-flex gap-5 justify-content-center"> 
                                <li><Link to="/impressum">IMPRESSUM</Link></li>
                                <li><Link to="/datenschutz">DATENSCHUTZ</Link></li> 
                                
                            </ul>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer