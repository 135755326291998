import React from 'react'
import book from '../../assets/img/banner-book.png'
import drone from '../../assets/img/banner-d.png'
import './Banner.scss'
const Banner = () => {
    return (
        <>
        <div className="banner-area" id='anterfaricrew'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="banner-text">
                            <h1><span>2090</span> <br /> Deine Zukunft </h1>
                            <p>Entdecke jetzt den ersten Band der "2090er" Reihe und gehe auf Reisen mit der Antarfari-Crew im Kampf gegen die Diktatur</p>
                            <a href="#leseprobe" className="btn-lg">ZUR LESEPROBE</a>
                        </div>
                    </div>
                    <div className="col-lg-5 ms-auto">
                        <div className="banner-img">
                            <span className='book-img'><img src={book} alt="" /></span>
                            <span className='drone-img'><img src={drone} alt="" /></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="banner-Ebook">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <p>
                        Ich fand das Buch sehr spannend und das Thema sehr interessant. Konnte das Buch schwer weglegen. Ich lese ja viel SF, aber die Idee der Geschichte ist wirklich aussergewöhnlich. Flüssig geschrieben und fast durchweg fesselnd geschrieben, ist das Buch schneller zu Ende als einem lieb ist. Das Thema ist auf jeden Fall cool, regt zum Nachdenken an. 
                        </p>
                        <h6>David H.</h6>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Banner